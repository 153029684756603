export default {
  accountant: 'Accountant',
  groupAdmin: 'GroupAdmin',
  superAdmin: 'SuperAdmin',
  teamLead: 'TeamLead',
  treasurer: 'Treasurer',
  memberAdder: 'MemberAdder',
  moneyCollector: 'MoneyCollector',
  ordinaryMember: 'OrdinaryMember',
  devUser: 'DevUser',
  groupOwner: 'GroupOwner',
}
