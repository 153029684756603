<template>
  <div class="app-union-list">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="members"
          :button-label="$manMudra.getters.isSuperAdmin() ? 'Add Member' : ''"
          :columns="memberColumns"
          :method-type="'PUT'"
          :total-column="'response.entries'"
          :data-column="'response.data'"
          :api-endpoint="'/api/User/ListAsync'"
          :extra-params="extraParams"
          :show-upload="true"
          :export-columns="exportColumns"
          :export-api-endpoint="'/api/User/ExportListAsync'"
          :generate-output="true"
          @add-new-info="openMemberPopup"
          @upload="chooseFile"
          @output-file="outputFile"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="openMember"
      :title="'Add New'"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="openMember = false"
    >
      <div class="col-md-12 text-center">
        <button
          class="btn btn-primary mx-1"
          @click="$helpers.goTo(that, 'group-member-add')"
        >
          Member
        </button>
        <button
          class="btn btn-primary mx-1"
          @click="$helpers.goTo(that, 'group-member-non-add')"
        >
          Non-Member
        </button>
      </div>
    </b-modal>
    <b-modal
      v-model="chooseFileModal"
      :title="'Upload Users'"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="chooseFileModal = false"
    >
      <b-form-group label="Choose File">
        <input
          id="usersFile"
          type="file"
          class="form-control"
        >
      </b-form-group>
      <div class="col-md-12 text-center">
        <button
          class="btn btn-primary mx-1"
          @click="validateUploadedExcel"
        >
          Process
        </button>
        <a
          class="btn btn-success mx-1"
          href="/assets/Import Peoples.xlsx"
          target="_blank"
        >
          Download Sample
        </a>
      </div>
    </b-modal>
    <b-modal
      v-model="showValidationModal"
      :title="'Review Users'"
      :no-close-on-backdrop="true"
      hide-footer
      size="xl"
      @hidden="showValidationModal = false"
    >
      <div
        class="col-md-12"
        style="max-height: 75vh; overflow: auto"
      >
        <div class="table-responsive">
          <table class="table table-bordered table-upload-section">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Action</th>
                <th>Error</th>
                <th v-if="usersValidation.nameVisible">
                  First Name
                </th>
                <th v-if="usersValidation.surnameVisible">
                  Surname
                </th>
                <th v-if="usersValidation.relationVisible">
                  Father or Husband Name
                </th>
                <th v-if="usersValidation.relationVisible">
                  RelationShip
                </th>
                <th v-if="usersValidation.memberVisible">
                  IsMember
                </th>
                <th
                  v-if="usersValidation.aadharVisible"
                  style="min-width: 210px"
                >
                  Aadhaar
                </th>
                <th v-if="usersValidation.organizationVisible">
                  Organization
                </th>
                <th
                  v-if="usersValidation.landlinePhoneVisible"
                  style="min-width: 210px"
                >
                  Landline
                </th>
                <th
                  v-if="usersValidation.mobileVisible"
                  style="min-width: 210px"
                >
                  Mobile
                </th>
                <th v-if="usersValidation.dateOfBirthVisible">
                  DOB
                </th>
                <th
                  v-if="usersValidation.sexVisible"
                  style="min-width: 150px"
                >
                  Gender
                </th>
                <th v-if="usersValidation.maritalVisible">
                  Marital Status
                </th>
                <th v-if="usersValidation.typeOfWorkVisible">
                  Work Type
                </th>
                <th v-if="usersValidation.suchWorkSinceVisible">
                  Years in Work
                </th>
                <th v-if="usersValidation.workLocalityVisible">
                  Work Locality
                </th>
                <th v-if="usersValidation.employerVisible">
                  Name of Employer
                </th>
                <th v-if="usersValidation.meanOfTransportVisible">
                  TransportMeans
                </th>
                <th v-if="usersValidation.categoryVisible">
                  Category
                </th>
                <th v-if="usersValidation.migrantFromVisible">
                  Migrant from (State)
                </th>
                <th v-if="usersValidation.casteVisible">
                  Caste
                </th>
                <th v-if="usersValidation.rationTypeVisible">
                  Ration Card Type
                </th>
                <th v-if="usersValidation.rationNoVisible">
                  Ration Card No
                </th>
                <th v-if="usersValidation.pensionNoVisible">
                  Pension Pay Order
                </th>
                <th v-if="usersValidation.jobCardVisible">
                  Job Card No
                </th>
                <th v-if="usersValidation.janAadharVisible">
                  Jan Aadhaar No.
                </th>
                <th v-if="usersValidation.shramikNoVisible">
                  Shramik Diary No.
                </th>
                <th>Papers Verified</th>
                <th v-if="usersValidation.bankVisible">
                  Bank Account No
                </th>
                <th v-if="usersValidation.ifscVisible">
                  IFSC
                </th>
                <th v-if="usersValidation.educationVisible">
                  Education
                </th>
                <th v-if="usersValidation.skillsVisible">
                  Skills
                </th>
                <th>Wants To Learn New Skills</th>
                <th>Leadership Roles</th>
                <th v-if="usersValidation.handicapVisible">
                  Handicap
                </th>
                <th v-if="usersValidation.earnAdultsVisible">
                  Earning Adults
                </th>
                <th v-if="usersValidation.nonEarningVisible">
                  Non Earning Adults
                </th>
                <th v-if="usersValidation.underageVisible">
                  Children
                </th>
                <th v-if="usersValidation.incomeCatVisible">
                  Income Category
                </th>
                <th v-if="usersValidation.emailVisible">
                  Email
                </th>
                <th v-if="usersValidation.stateVisible">
                  State
                </th>
                <th v-if="usersValidation.districtVisible">
                  District
                </th>
                <th v-if="usersValidation.tahsilVisible">
                  SubDistrict
                </th>
                <th v-if="usersValidation.blockVisible">
                  Block
                </th>
                <th v-if="usersValidation.panchayatVisible">
                  Panchayat
                </th>
                <th v-if="usersValidation.townVillageVisible">
                  CityOrVillage
                </th>
                <th v-if="usersValidation.pincodeVisible">
                  PinCode
                </th>
                <th v-if="usersValidation.houseNoVisible">
                  House No.
                </th>
                <th>LandMark</th>
                <th v-if="usersValidation.postOfficeVisible">
                  PostOffice
                </th>
                <th>IsUrban</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) of usersList"
                :key="index"
                :class="{ 'has-error': !user.isSuccess }"
              >
                <td>
                  <p class="mb-0">
                    {{ index + 1 }}
                  </p>
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="removeUser(index)"
                  >
                    Remove
                  </button>
                </td>
                <td>
                  <p class="mb-0 text-danger">
                    {{ user.message }}
                  </p>
                </td>
                <td v-if="usersValidation.nameVisible">
                  <input
                    v-model="user.firstName"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.surnameVisible">
                  <input
                    v-model="user.surname"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.relationVisible">
                  <input
                    v-model="user.fatherOrHusbandName"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.relationVisible">
                  <input
                    v-model="user.relationShip"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.memberVisible">
                  <input
                    v-model="user.isMember"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.aadharVisible">
                  <input
                    v-model="user.aadhaarNo"
                    v-mask="'####-####-####'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.organizationVisible">
                  <input
                    v-model="user.organization"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.landlinePhoneVisible">
                  <input
                    v-model="user.landlinePhone"
                    v-mask="'#####-#####'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.mobileVisible">
                  <input
                    v-model="user.mobileNo"
                    v-mask="'#####-#####'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.dateOfBirthVisible">
                  <input
                    v-model="user.dateOfBirth"
                    type="date"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.sexVisible">
                  <input
                    v-model="user.gender"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.maritalVisible">
                  <input
                    v-model="user.maritalStatus"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.typeOfWorkVisible">
                  <input
                    v-model="user.workType"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.suchWorkSinceVisible">
                  <input
                    v-model="user.yearsInWork"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.workLocalityVisible">
                  <input
                    v-model="user.workLocality"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.employerVisible">
                  <input
                    v-model="user.employerName"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.meanOfTransportVisible">
                  <input
                    v-model="user.transportMeans"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.categoryVisible">
                  <input
                    v-model="user.category"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.migrantFromVisible">
                  <input
                    v-model="user.migrantFrom"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.casteVisible">
                  <input
                    v-model="user.caste"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.rationTypeVisible">
                  <input
                    v-model="user.rationCardType"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.rationNoVisible">
                  <input
                    v-model="user.rationCardNo"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.pensionNoVisible">
                  <input
                    v-model="user.pensionPayOrder"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.jobCardVisible">
                  <input
                    v-model="user.jobCardNo"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.janAadharVisible">
                  <input
                    v-model="user.janAadhaarNo"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.shramikNoVisible">
                  <input
                    v-model="user.shramikDiaryNo"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td>
                  <input
                    v-model="user.papersVerified"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.bankVisible">
                  <input
                    v-model="user.bankAccountNo"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.ifscVisible">
                  <input
                    v-model="user.ifsc"
                    v-mask="'AAAA0XXXXXX'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.educationVisible">
                  <input
                    v-model="user.education"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.skillsVisible">
                  <input
                    v-model="user.skills"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td>
                  <input
                    v-model="user.wantsToLearnNewSkills"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td>
                  <input
                    v-model="user.leadershipRoles"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.handicapVisible">
                  <input
                    v-model="user.handicap"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.earnAdultsVisible">
                  <input
                    v-model="user.earningAdults"
                    v-mask="'##'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.nonEarningVisible">
                  <input
                    v-model="user.nonEarningAdults"
                    v-mask="'##'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.underageVisible">
                  <input
                    v-model="user.children"
                    v-mask="'##'"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.incomeCatVisible">
                  <input
                    v-model="user.incomeCategory"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.emailVisible">
                  <input
                    v-model="user.email"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.stateVisible">
                  <input
                    v-model="user.address.state"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.districtVisible">
                  <input
                    v-model="user.address.district"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.tahsilVisible">
                  <input
                    v-model="user.address.subDistrict"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.blockVisible">
                  <input
                    v-model="user.address.block"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.panchayatVisible">
                  <input
                    v-model="user.address.panchayat"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.townVillageVisible">
                  <input
                    v-model="user.address.cityOrVillage"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.pincodeVisible">
                  <input
                    v-model="user.address.pinCode"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.houseNoVisible">
                  <input
                    v-model="user.address.houseNo"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td>
                  <input
                    v-model="user.address.landMark"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td v-if="usersValidation.postOfficeVisible">
                  <input
                    v-model="user.address.postOffice"
                    type="text"
                    class="form-control"
                  >
                </td>
                <td>
                  <input
                    v-model="user.address.isUrban"
                    type="text"
                    class="form-control"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 text-center mt-2">
        <button
          class="btn btn-primary mx-1"
          @click="saveUsers"
        >
          Save Users
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BFormGroup,
} from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import {
  appDeleteMember,
  appUpsertImportedUser,
  appUploadUsers,
  appGetGroupSetting,
  appExportUserExcel,
  appUploadUsersAndvalidate,
} from '@/@core/services/groups'
import {
  hideLoader,
  showDangerNotification,
  showErrorNotification,
  showLoader,
  showSuccessNotification,
} from '@/@core/comp-functions/ui/app'
import roles from '@/@core/services/roles'

export default {
  components: {
    GoodDataTable,
    BRow,
    BFormGroup,
    BCol,
    BModal,
  },
  data() {
    return {
      that: this,
      openMember: false,
      usersList: [],
      chooseFileModal: false,
      showValidationModal: false,
      usersValidation: {},
      exportColumns: [
        {
          label: 'Id',
          value: 'id',
        },
        {
          label: 'FirstName',
          value: 'firstName',
          canExportKey: 'nameVisible',
        },
        {
          label: 'Surname',
          value: 'surname',
          canExportKey: 'surnameVisible',
        },
        {
          label: 'FatherOrHusbandName',
          value: 'fatherOrHusbandName',
          canExportKey: 'relationVisible',
        },
        {
          label: 'RelationShip',
          value: 'relationShip',
          canExportKey: 'relationVisible',
        },
        {
          label: 'IsMember',
          value: 'isMember',
          canExportKey: 'memberVisible',
        },
        {
          label: 'AadhaarNo',
          value: 'aadhaarNo',
          canExportKey: 'aadharVisible',
        },
        {
          label: 'Organization',
          value: 'organization',
          canExportKey: 'organizationRequired',
        },
        {
          label: 'LandlinePhone',
          value: 'landlinePhone',
          canExportKey: 'landlinePhoneVisible',
        },
        {
          label: 'MobileNo',
          value: 'mobileNo',
          canExportKey: 'mobileVisible',
        },
        {
          label: 'DateOfBirth',
          value: 'dateOfBirth',
          canExportKey: 'dateOfBirthVisible',
        },
        {
          label: 'Gender',
          value: 'gender',
          canExportKey: 'sexVisible',
        },
        {
          label: 'MaritalStatus',
          value: 'maritalStatus',
          canExportKey: 'maritalVisible',
        },
        {
          label: 'WorkType',
          value: 'workType',
          canExportKey: 'typeOfWorkVisible',
        },
        {
          label: 'YearsInWork',
          value: 'yearsInWork',
          canExportKey: 'suchWorkSinceVisible',
        },
        {
          label: 'WorkLocality',
          value: 'workLocality',
          canExportKey: 'workLocalityVisible',
        },
        {
          label: 'EmployerName',
          value: 'employerName',
          canExportKey: 'employerVisible',
        },
        {
          label: 'TransportMeans',
          value: 'transportMeans',
          canExportKey: 'meanOfTransportVisible',
        },
        {
          label: 'Category',
          value: 'category',
          canExportKey: 'categoryVisible',
        },
        {
          label: 'MigrantFrom',
          value: 'migrantFrom',
          canExportKey: 'migrantFromVisible',
        },
        {
          label: 'Caste',
          value: 'caste',
          canExportKey: 'casteVisible',
        },
        {
          label: 'RationCardType',
          value: 'rationCardType',
          canExportKey: 'rationTypeVisible',
        },
        {
          label: 'RationCardNo',
          value: 'rationCardNo',
          canExportKey: 'rationNoVisible',
        },
        {
          label: 'PensionPayOrder',
          value: 'pensionPayOrder',
          canExportKey: 'pensionNoVisible',
        },
        {
          label: 'JobCardNo',
          value: 'jobCardNo',
          canExportKey: 'jobCardVisible',
        },
        {
          label: 'JanAadhaarNo',
          value: 'janAadhaarNo',
          canExportKey: 'janAadharVisible',
        },
        {
          label: 'ShramikDiaryNo',
          value: 'shramikDiaryNo',
          canExportKey: 'shramikNoVisible',
        },
        {
          label: 'PapersVerified',
          value: 'papersVerified',
          canExportKey: '',
        },
        {
          label: 'BankAccountNo',
          value: 'bankAccountNo',
          canExportKey: 'bankVisible',
        },
        {
          label: 'Ifsc',
          value: 'ifsc',
          canExportKey: 'ifscVisible',
        },
        {
          label: 'Education',
          value: 'education',
          canExportKey: 'educationVisible',
        },
        {
          label: 'Skills',
          value: 'skills',
          canExportKey: 'skillsVisible',
        },
        {
          label: 'WantsToLearnNewSkills',
          value: 'wantsToLearnNewSkills',
          canExportKey: '',
        },
        {
          label: 'LeadershipRoles',
          value: 'leadershipRoles',
          canExportKey: '',
        },
        {
          label: 'Handicap',
          value: 'handicap',
          canExportKey: 'handicapVisible',
        },
        {
          label: 'EarningAdults',
          value: 'earningAdults',
          canExportKey: 'earnAdultsVisible',
        },
        {
          label: 'NonEarningAdults',
          value: 'nonEarningAdults',
          canExportKey: 'nonEarningVisible',
        },
        {
          label: 'Children',
          value: 'children',
          canExportKey: 'underageVisible',
        },
        {
          label: 'Income Category',
          value: 'incomeCategory',
        },
        {
          label: 'Email',
          value: 'email',
          canExportKey: 'emailVisible',
        },
        {
          label: 'State Name',
          value: 'address.stateName',
          canExportKey: 'stateVisible',
        },
        {
          label: 'District Name',
          value: 'address.districtName',
          canExportKey: 'districtVisible',
        },
        {
          label: 'SubDistrict Name',
          value: 'address.subDistrictName',
          canExportKey: 'tahsilVisible',
        },
        {
          label: 'Block Name',
          value: 'address.blockName',
          canExportKey: 'blockVisible',
        },
        {
          label: 'Panchayat Name',
          value: 'address.panchayatName',
          canExportKey: 'panchayatVisible',
        },
        {
          label: 'CityOrVillage',
          value: 'address.cityOrVillage',
          canExportKey: 'townVillageVisible',
        },
        {
          label: 'PinCode',
          value: 'address.pinCode',
          canExportKey: 'pincodeVisible',
        },
        {
          label: 'HouseNo',
          value: 'address.houseNo',
          canExportKey: 'houseNoVisible',
        },
        {
          label: 'LandMark',
          value: 'address.landMark',
          canExportKey: '',
        },
        {
          label: 'PostOffice',
          value: 'address.postOffice',
          canExportKey: 'postOfficeVisible',
        },
        {
          label: 'Is Urban',
          value: 'address.isUrban',
          canExportKey: '',
        },
      ],
    }
  },
  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
    unionList() {
      if (this.$store.getters['groups/unionList']) {
        return this.$store.getters['groups/unionList']
      }
      return []
    },
    extraParams() {
      if (!this.unionSelected) return ''
      return `unionId=${this.unionSelected.id}&onlyMembers=false&onlyMoneyCollectors=false&textToSearch=`
    },
    memberColumns() {
      return [
        {
          label: 'Name',
          field: 'firstName',
          useRenderer: true,
          renderer: props => [props.firstName, props.surname].filter(x => x).join(' '),
        },
        {
          label: 'Aadhaar No',
          field: 'aadhaarNo',
        },
        {
          label: 'Mobile No',
          field: 'mobileNo',
        },
        {
          label: 'Member of Additional Unions',
          field: 'unionMemberships',
          useHtml: true,
          excelHtml: props => {
            if (Array.isArray(props.unionMemberships)) {
              const memberships = window.ManMudra.actions
                .cloneDeep(props.unionMemberships)
                .slice(0, 4)
              return this.unionList
                .filter(x => memberships.some(y => y.unionId === x.id))
                .map(x => x.name)
                .join(', ')
            }
            return ''
          },
          renderer: props => {
            if (!this.unionSelected) return ''
            if (Array.isArray(props.unionMemberships)) {
              const memberships = window.ManMudra.actions
                .cloneDeep(props.unionMemberships)
                .filter(x => x.unionId !== this.unionSelected.id)
                .slice(0, 3)
              return this.unionList
                .filter(x => memberships.some(y => y.unionId === x.id))
                .map((x, i) => `<p class="mb-0">Union ${i + 1}: ${x.name}</p>`)
                .join('')
            }
            return ''
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.$helpers.goTo(this, 'group-member-edit', {
                  memberId: props.id,
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.deleteMember(props.id)
              },
            },
          ].filter(x => (window.ManMudra.getters.isSuperAdmin() ? x : x.text !== 'Edit')),
        },
      ]
    },
  },
  watch: {
    unionSelected: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.runInitial()
        }, 150)
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.getGroupValidation()
    }, 1000)
  },
  methods: {
    outputFile(base64) {
      showLoader()
      appExportUserExcel({
        base64,
      }).then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `user-data-${new Date().valueOf()}.xlsx`
        link.click()
      })
    },
    removeUser(index) {
      // eslint-disable-next-line eqeqeq
      this.usersList = this.usersList.filter((_, i) => i != index)
    },
    async saveUsers() {
      const promises = []
      this.usersList.forEach(x => {
        // eslint-disable-next-line no-param-reassign
        x.isSuccess = true
        // eslint-disable-next-line no-param-reassign
        x.message = ''
      })
      let counter = 0
      showLoader()
      // eslint-disable-next-line no-restricted-syntax
      for (const user of this.usersList) {
        // eslint-disable-next-line no-loop-func
        const promise = new Promise(resolve => {
          user.roles = [roles.ordinaryMember]
          user.unionId = this.unionSelected.id
          if (user.dateOfBirth && user.dateOfBirth.includes('NaN')) {
            user.dateOfBirth = null
          }
          if (user.dateOfBirth && !new Date(user.dateOfBirth).toJSON()) {
            user.dateOfBirth = null
          }
          appUpsertImportedUser(user)
            .then(({ data }) => {
              const findUser = this.usersList.findIndex(
                x => x.id === user.id,
              )
              if (data.succeeded) {
                if (findUser >= 0) {
                  this.usersList[findUser].isSuccess = true
                  // eslint-disable-next-line operator-assignment
                  counter = counter + 1
                }
              } else if (findUser >= 0) {
                this.usersList[findUser].isSuccess = false
                this.usersList[findUser].message = data.message
              }
              resolve(true)
            })
            .catch(() => {
              const findUser = this.usersList.findIndex(
                x => x.id === user.id,
              )
              if (findUser >= 0) {
                this.usersList[findUser].isSuccess = false
                this.usersList[findUser].message = 'Error occured'
              }
              resolve(true)
            })
        })
        promises.push(promise)
      }
      Promise.all(promises).then(() => {
        this.usersList = this.usersList.filter(x => !x.isSuccess)
        if (counter > 0) {
          showSuccessNotification(this, `${counter} members added`)
        }
        if (this.usersList.length === 0) {
          this.showValidationModal = false
        }
        hideLoader()
      })
    },
    uploadUsers() {
      const file = document.getElementById('usersFile')
      if (file && file.files && file.files[0]) {
        const formData = new FormData()
        formData.append('file', file.files[0])
        showLoader()
        appUploadUsers(formData)
          .then(({ data }) => {
            if (data.succeeded) {
              data.data.forEach(x => {
                // eslint-disable-next-line no-param-reassign
                x.isSuccess = true
                // eslint-disable-next-line no-param-reassign
                x.message = ''
                if (x.dateOfBirth) {
                  // eslint-disable-next-line no-param-reassign
                  x.dateOfBirth = this.$helpers.formatDate(x.dateOfBirth)
                }
              })
              this.usersList = data.data
              this.chooseFileModal = false
              this.showValidationModal = true
              document.getElementById('usersFile').value = ''
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            hideLoader()
            showErrorNotification(this, error)
          })
      }
    },
    validateUploadedExcel() {
      const unionId = this.unionSelected.id
      const file = document.getElementById('usersFile')
      if (file && file.files && file.files[0]) {
        const formData = new FormData()
        formData.append('file', file.files[0])
        formData.append('unionId', unionId)
        showLoader()
        appUploadUsersAndvalidate(formData).then(response => {
          if (response.data.type === 'application/json') {
            this.uploadUsers()
          } else {
            this.chooseFileModal = false
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `user-data-${new Date().valueOf()}.xlsx`
            link.click()
            showDangerNotification(this, 'Please enter required fields in file and process again')
          }
          hideLoader()
        })
      }
    },
    chooseFile() {
      this.chooseFileModal = true
    },
    runInitial() {
      if (this.$refs.members) {
        this.$refs.members.loadItems()
      }
    },
    openMemberPopup() {
      this.openMember = true
    },
    deleteMember(memberId) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to delete?')) {
        appDeleteMember({ id: memberId }).then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, data.message)
            this.$refs.members.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
        })
      }
    },
    getGroupValidation() {
      appGetGroupSetting({
        id: this.unionSelected.id,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.usersValidation = data.data
          // this.exportColumns = this.exportColumns.filter(
          //   x => !x.canExportKey
          //     || x.canExportKey
          //       === (data.data[x.canExportKey] === true ? x.canExportKey : ''),
          // )
        } else {
          showDangerNotification(data.message)
        }
        this.groupValidationLoaded = true
      })
    },
  },
}
</script>

<style lang="scss">
.table-upload-section {
  th {
    white-space: nowrap;
  }

  .has-error {
    td {
      input {
        border: 1px solid #ea5455 !important;
      }
    }
  }
}
</style>
